import { action, computed, thunk } from "easy-peasy";
import { matchesProperty } from "lodash";
import { err, ok } from "neverthrow";
import koalaApiClient from "src/apis/koala-api-client";
import { ShemsiApiClient } from "src/apis/shemsi-api-client";
import koalaUtils from "src/helpers/koala/koalaUtils";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import removeFromLocalStorage from "src/helpers/local-storage/removeFromLocalStorage";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";
import { DiscountType } from "src/types/discount/DiscountType";
import { KoalaInsertCart } from "src/types/koala/KoalaCart";
import { KoalaVariant } from "src/types/koala/KoalaVariant";
import { CountryCode } from "src/types/localization/CountryCode";
import { StoreModel } from "src/types/store/StoreModel";

const shemsiApiClient = new ShemsiApiClient();

const storeModel: StoreModel = {
  // states
  cart: null,
  oosItems: computed(function getOOSItems(state) {
    if (!state.cart) return [];

    return koalaUtils.getOOSItems(state.cart, state.products);
  }),
  checkout: null,
  isCartSidebarExpanded: false,
  products: [],

  // Actions
  setProducts: action(function addProducts(state, payload) {
    state.products = payload;
  }),

  setCart: action(function setCart(state, payload) {
    state.cart = payload;
  }),

  resetCart: action(function resetCart(state, payload) {
    removeFromLocalStorage(`koala_cart_id_${payload}`);
    state.cart = null;
  }),

  setCheckout: action(function setCheckout(state, payload) {
    state.checkout = payload;
  }),

  resetCheckout: action(function resetCheckout(state, payload) {
    removeFromLocalStorage(`checkout_id_${payload}`);
    state.checkout = null;
  }),

  setIsCartSidebarExpanded: action(function setIsCartSidebarExpanded(state, payload) {
    state.isCartSidebarExpanded = payload;
  }),

  // Thunks
  fetchProductsThunk: thunk(async function fetchProductsThunk(actions, payload) {
    const productsResult = await koalaApiClient.getProducts({ countryCode: payload });
    if (productsResult.isOk()) {
      const filteredProducts = productsResult.value.data.filter(function hasVariantImages(product) {
        return product.variants.every(function hasAtLeastOneImage(variant) {
          return variant.images.length >= 1;
        });
      });
      actions.setProducts(filteredProducts);
    }
  }),

  fetchCartThunk: thunk(async function fetchCartThunk(actions, payload) {
    const cartResult = await koalaApiClient.getCartById(payload);
    if (cartResult.isOk()) {
      actions.setCart(cartResult.value);
    }
    return cartResult;
  }),

  createCartThunk: thunk(async function createCartThunk(actions, payload) {
    const cart: KoalaInsertCart = {
      currency: payload.countryData.currency,
      lineItems: koalaUtils.applyBundleDiscountToCartItems(
        payload.lineItems,
        payload.countryData.bundleDiscountSystems || []
      ),
      countryCode: payload.countryData.code,
      deployment: payload.locale,
      attributes: payload.attributes,
    };
    const result = await koalaApiClient.createCart(cart, payload.countryData, payload.deliveryMethod);
    if (result.isOk()) {
      setLocalStorage(`koala_cart_id_${payload.countryData.code}`, result.value.data._id);
      actions.setCart(result.value.data);
      actions.setIsCartSidebarExpanded(true);
    }
    return result;
  }),

  updateCartItemsThunk: thunk(async function updateCartItemsThunk(actions, payload, { getState }) {
    const oldLineItems = getState().cart?.lineItems || [];
    let newLineItems = koalaUtils.updateCartItemQuantity(oldLineItems, payload.newItem);
    newLineItems = koalaUtils.applyBundleDiscountToCartItems(
      newLineItems,
      payload.countryData.bundleDiscountSystems || []
    );
    const result = await koalaApiClient.updateCartItems(
      payload.cartId,
      newLineItems,
      payload.countryData,
      payload.deliveryMethod
    );
    if (result.isOk()) {
      actions.setCart(result.value.data);
      actions.setIsCartSidebarExpanded(true);
    }
    return result;
  }),

  removeOOSItems: thunk(async function removeOOSItems(actions, payload, { getState }) {
    for (const item of getState().oosItems) {
      await actions.updateCartItemsThunk({
        cartId: getState().cart?._id as string,
        newItem: { variantId: item.variantId, sku: item.sku, quantity: item.quantityAvailable },
        countryData: payload.countryData,
        deliveryMethod: payload.deliveryMethod,
      });
    }
  }),

  updateCartShippingFeesThunk: thunk(async function updateCartItemsThunk(actions, payload) {
    const result = await koalaApiClient.updateCartShipping(payload.cartId, payload.shippingFees);
    if (result.isOk()) {
      actions.setCart(result.value.data);
    }
    return result;
  }),

  applyDiscountCodeThunk: thunk(async function applyDiscountCodeThunk(actions, payload) {
    const result = await koalaApiClient.applyDiscountCode(payload.cartId, payload.email, payload.discountCode);
    if (result.isOk()) {
      actions.setCart(result.value.data);
    }
    return result;
  }),

  applyDiscountThunk: thunk(async function applyDiscountThunk(actions, payload) {
    const result = await koalaApiClient.applyDiscount(payload.cartId, payload.discount);
    if (result.isOk()) {
      actions.setCart(result.value.data);
    }
    return result;
  }),

  applyReferralDiscountThunk: thunk(async function applyReferralDiscountThunk(actions, payload) {
    const { cart, referralConfig, user, countryData } = payload;

    // Exit if referral discount is already applied to cart
    const isDiscountApplied = cart.discounts.some(matchesProperty("type", DiscountType.referral_code));
    if (isDiscountApplied) {
      const errorMessage = `🤷🏻‍♀️: Referral code: ${referralConfig.code} is already applied to cart: ${cart._id}`;
      console.warn(errorMessage);
      return err({ errorMessage });
    }

    // Exit if user uses his own referral code
    if (user && user.referralConfigs.some((config) => config.code === referralConfig.code)) {
      const errorMessage = `🤷🏻‍♀️: User: ${user.email} is trying to use his own Referral code: ${referralConfig.code}`;
      console.warn(errorMessage);
      return err({ errorMessage });
    }

    // Exit if referral code is used in a previous order
    if (countryData.referralConfig?.newRefereeOnly) {
      const storageUsedReferralCodes: string[] = getLocalStorage("used_referral_codes") || [];
      const usedOnce = storageUsedReferralCodes.includes(referralConfig.code);
      if (usedOnce) {
        const errorMessage = `🤷🏻‍♀️: Referral code: ${referralConfig.code} is used for a previous order`;
        console.warn(errorMessage);
        return err({ errorMessage });
      }
    }

    // Let's go 🚀
    const applyReferralDiscountResult = await shemsiApiClient.koalaApplyReferralDiscount(
      countryData.code,
      referralConfig.code,
      cart._id
    );

    if (applyReferralDiscountResult.isErr()) {
      return err(applyReferralDiscountResult.error);
    }

    actions.setCart(applyReferralDiscountResult.value);
    return ok(applyReferralDiscountResult.value);
  }),

  spendCreditsThunk: thunk(async function spendCreditsThunk(actions, payload) {
    const result = await shemsiApiClient.spendCredits(payload.cart, payload.creditsAmount, payload.creditFactor);
    if (result.isErr()) {
      return err({ errorMessage: result.error.errorMessage });
    }
    actions.setCart(result.value);
    return result;
  }),

  unspendCreditsThunk: thunk(async function unspendCreditsThunk(actions, payload) {
    const result = await shemsiApiClient.unspendCredits(
      payload.email,
      payload.spentCredits,
      payload.creditFactor,
      payload.cart
    );
    if (result.isErr()) {
      return err({ errorMessage: result.error.errorMessage });
    }
    actions.setCart(result.value);
    return result;
  }),

  fetchCheckoutThunk: thunk(async function fetchCheckoutThunk(actions, payload) {
    const checkoutResult = await koalaApiClient.getCheckoutById(payload);
    if (checkoutResult.isOk()) {
      console.log(checkoutResult);
      if (checkoutResult?.value?.orderId) {
        actions.resetCheckout(checkoutResult.value.countryCode as CountryCode);
      } else {
        actions.setCheckout(checkoutResult.value);
      }
    }
    return checkoutResult;
  }),

  createCheckoutThunk: thunk(async function createCheckoutThunk(actions, payload) {
    const result = await koalaApiClient.createCheckout(payload.cartId, payload.customer);

    if (result.isOk()) {
      setLocalStorage(`checkout_id_${payload.countryCode}`, result.value.data._id);
      actions.setCheckout(result.value.data);
    }
    return result;
  }),

  updateCheckoutCustomerThunk: thunk(async function updateCheckoutCustomerThunk(actions, payload) {
    const result = await koalaApiClient.updateCheckoutCustomer(payload.checkoutId, payload.customer);

    if (result.isOk()) {
      actions.setCheckout(result.value.data);
    }
    return result;
  }),

  updateDeliveryMethodThunk: thunk(async function updateDeliveryMethodThunk(actions, payload) {
    const result = await koalaApiClient.updateDeliveryMethod(
      payload.checkoutId,
      payload.deliveryMethod,
      payload.pickupPointMetadata
    );

    if (result.isOk()) {
      actions.setCheckout(result.value.data);
    }
    return result;
  }),
};

export default storeModel;

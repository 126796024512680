import useCountryData from "./useCountryData";
import { useEffect, useState } from "react";
import generatePriceBeforeDiscount from "src/helpers/generatePriceBeforeDiscount";
import consts from "src/config/consts";

export default function usePriceBeforeDiscount(price?: number, handle?: string) {
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState<number>(NaN);
  const countryData = useCountryData();

  useEffect(
    function () {
      if (price && countryData?.splitTestDiscount && handle) {
        const result = generatePriceBeforeDiscount(
          price,
          countryData.fakeDiscountPerProduct?.[handle] || consts.FAKE_DISCOUNT_PERCENTAGE_VALUE
        );
        setPriceBeforeDiscount(result);
      }
    },
    [countryData, price]
  );

  return priceBeforeDiscount;
}

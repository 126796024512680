import { useTranslations } from "next-intl";
import { useContext } from "react";
import { CreditsContext, CreditsContextProps } from "src/context/CreditsContext";
import { DeliveryOption, PaymentContext, PaymentProps, PaymentState } from "src/context/payment";
import Country from "src/helpers/localization-helpers/countryClass";
import SpinIcon from "../icon/BlackSpinIcon";
import DiscountItem from "./components/DiscountItem";
import TabbyPromo from "../tabby/TabbyPromo";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { AuthenticationContext, AuthenticationContextProps } from "src/context/authentication";
import { KoalaCart } from "src/types/koala/KoalaCart";
import useKoalaSummaryDetails from "src/hooks/useKoalaSummaryDetails";
import generatePriceBeforeDiscount from "src/helpers/generatePriceBeforeDiscount";
import consts from "src/config/consts";
import { useStoreActions } from "src/hooks/storeHooks";
import useNeverthrowAsync from "src/hooks/useNeverthrowAsync";

type KoalaSummaryDetailsProps = {
  cart: KoalaCart;
  countryData: Country;
  inCheckoutPage?: boolean;
};

function KoalaSummaryDetails({ cart, countryData, inCheckoutPage = false }: KoalaSummaryDetailsProps) {
  // dependencies
  const t = useTranslations("SummaryDetails");
  const { paymentStep, deliveryOption } = useContext(PaymentContext) as PaymentProps;
  const { user } = useContext(AuthenticationContext) as AuthenticationContextProps;
  const { creditFactor, netCredits, creditsToSpend, creditsAreUsed, spentCredits } = useContext(
    CreditsContext
  ) as CreditsContextProps;
  const summaryDetails = useKoalaSummaryDetails(cart);
  const spendCreditsThunk = useStoreActions((actions) => actions.spendCreditsThunk);
  const unspendCreditsThunk = useStoreActions((actions) => actions.unspendCreditsThunk);

  const [handleSpendCredits, isSpendCreditLoading] = useNeverthrowAsync(spendCreditsThunk);
  const [handleUnspendCredits, isUnspendCreditLoading] = useNeverthrowAsync(unspendCreditsThunk);

  /**
   * Fake subtotal
   */
  let subtotalPrice = summaryDetails.subtotalPrice;
  // if (countryData.splitTestDiscount) {
  //   subtotalPrice = generatePriceBeforeDiscount(subtotalPrice, consts.FAKE_DISCOUNT_PERCENTAGE_VALUE);
  // }
  // Calculate shipping fees based on delivery option selected
  const shippingFees =
    deliveryOption === DeliveryOption.pickup && countryData.pickupShipping && countryData.pickupShipping.fees
      ? countryData.pickupShipping.fees
      : countryData.shipping.fees;

  return (
    <div>
      <ul className="flex flex-col justify-between gap-1 border-b border-gray-300 pb-4">
        {/* Subtotal */}
        <li className="flex justify-between">
          <p>{t("subtotal")}</p>
          <p id="subtotal">
            {t("price", {
              amount: subtotalPrice,
              currency: cart.currency,
            })}
          </p>
        </li>

        {summaryDetails.discounts.map((discount, index) => (
          <li key={index}>
            <DiscountItem
              currency={cart.currency}
              discountType={discount.type}
              discountName={discount.name}
              discountedAmount={Math.round(discount.value)}
            />
          </li>
        ))}

        {/* Shipping */}
        <li>
          <div className="flex justify-between">
            <p>{t("vip_shipping")}</p>
            {countryData.checkoutMethod === "in-house" &&
              (summaryDetails.totalPriceBeforeShipping >= countryData.shipping.freeLimit ? (
                <p>
                  <span className="line-through">
                    {t("price", {
                      amount: shippingFees,
                      currency: cart.currency,
                    })}
                  </span>
                  <span className="ms-2 font-semibold">{t("free")}</span>
                </p>
              ) : (
                <p>
                  {t("price", {
                    amount: shippingFees,
                    currency: cart.currency,
                  })}
                </p>
              ))}
            {countryData.checkoutMethod === "standard" && <p>{t("calculated_at_checkout")}</p>}
          </div>
          {inCheckoutPage && (
            <div>
              <p className="mt-1 text-xs md:mt-0 md:w-2/3">{t("shipping_subtext")}</p>
            </div>
          )}
        </li>
      </ul>

      {/* Credits */}
      {(creditsToSpend || spentCredits) && creditFactor && cart && user && paymentStep !== PaymentState.successPage ? (
        <div>
          <div className="flex justify-between border-b border-gray-300 py-4">
            <p>{t("credits_amount", { credits: netCredits })}</p>
            <label className="flex items-center gap-2">
              {(isSpendCreditLoading || isUnspendCreditLoading) && <SpinIcon />}
              <input
                id="summary-details__credits-input"
                type="checkbox"
                className="h-4 w-4 rounded border-black text-black focus:ring-2 focus:ring-black"
                checked={creditsAreUsed}
                onChange={(event) => {
                  if (event.target.checked && creditsToSpend) {
                    handleSpendCredits({ cart, creditsAmount: creditsToSpend, creditFactor });
                  } else if (!event.target.checked && spentCredits) {
                    handleUnspendCredits({ email: user.email, cart, spentCredits, creditFactor });
                  }
                }}
              />
              <p>
                {creditsAreUsed
                  ? t("used_credits", { credits: spentCredits })
                  : t("use_credits", { credits: creditsToSpend })}
              </p>
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* total */}
      <div className="flex justify-between pt-4">
        <p className="text-xl font-semibold">{t("total")}</p>
        <p className="font-bold" id="total">
          {t("price", {
            amount: Math.round(summaryDetails.totalPrice),
            currency: cart.currency,
          })}
        </p>
      </div>

      {/* Tabby Promo */}
      {countryData?.paymentMethods?.includes(SupportedPaymentMethod.tabby) && (
        <TabbyPromo price={Math.round(summaryDetails.totalPrice)} currency={countryData.currency} />
      )}

      {countryData.checkoutMethod === "in-house" && (
        <div>
          <p className="text-sm">{t("incl_everything")}</p>
        </div>
      )}
    </div>
  );
}

export default KoalaSummaryDetails;

import { CreditsConfig } from "src/types/credits";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { BundleDiscountSystem } from "src/types/bundle-discounts/BundleDiscountSystem";
import { CountryCode } from "src/types/localization/CountryCode";
import { CountryName } from "src/types/localization/CountryName";
import { Currency } from "src/types/localization/Currency";
import { Language } from "src/types/localization/Language";
import { Province } from "src/types/localization/Province";
import { ProductInternalData } from "src/types/shopify/ProductInternalData";
import { StoreLocation } from "src/types/store-locations";
import { ReferralConfig } from "src/types/localization/ReferralConfig";
import { Amount } from "src/types/localization/Amount";
import { OMSConnector } from "src/types/localization/OMSConnector";

interface ShopifyKeys {
  STORE_NAME: string;
  STOREFRONT_TOKEN: string;
  ADMIN_TOKEN: string;
}

interface Shipping {
  line: {
    title: string;
    price: string;
  };
  fees: number;
  freeLimit: number;
}

interface SocialInfo {
  WHATSAPP_SUPPORT_NUMBER: string;
  FACEBOOK_PAGE_HANDLE: string;
  INSTAGRAM_HANDLE: string;
  YOUTUBE_CHANNEL: string;
  TIKTOK_CHANNEL: string;
  SUPPORT_START_HOUR: number;
  SUPPORT_END_HOUR: number;
}

type Region = "MENA" | "NA";

enum CollectionName {
  COLLECTION_ALL_ACTIVE_PRODUCTS = "COLLECTION_ALL_ACTIVE_PRODUCTS",
  COLLECTION_ALL_SINGLE_PRODUCTS = "COLLECTION_ALL_SINGLE_PRODUCTS",
  COLLECTION_ALL_PACKS = "COLLECTION_ALL_PACKS",
  COLLECTION_LADIES = "COLLECTION_LADIES",
  COLLECTION_TEENS = "COLLECTION_TEENS",
  COLLECTION_UNDERWEARS = "COLLECTION_UNDERWEARS",
  COLLECTION_GIFTS = "COLLECTION_GIFTS",
  COLLECTION_ACCESSORIES = "COLLECTION_ACCESSORIES",
}

enum KoalaCollectionName {
  COLLECTION_ALL_ACTIVE_PRODUCTS = "COLLECTION_ALL_ACTIVE_PRODUCTS",
  COLLECTION_LADIES = "COLLECTION_LADIES",
  COLLECTION_TEENS = "COLLECTION_TEENS",
  COLLECTION_UNDERWEARS = "COLLECTION_UNDERWEARS",
  COLLECTION_ACCESSORIES = "COLLECTION_ACCESSORIES",
}

export interface CollectionConfig {
  id: number;
  name: string;
  isActive: boolean;
  hasSeparateList: boolean;
  isCategory: boolean;
  isDefault: boolean;
  isInPopover: boolean;
  hasBundleDiscount: boolean;
}

export type CollectionConfigs = {
  [key in CollectionName]: CollectionConfig;
};

export interface KoalaCollectionConfig {
  id: string;
  name: string;
  isActive: boolean;
  hasSeparateList: boolean;
  isCategory: boolean;
  isDefault: boolean;
  isInPopover: boolean;
  hasBundleDiscount: boolean;
}

export type KoalaCollectionConfigs = {
  [key in KoalaCollectionName]: KoalaCollectionConfig;
};

interface CountryProps {
  name: CountryName;
  code: CountryCode;
  currency: Currency;
  numberOfCents: number;
  region: Region;
  phoneExample: string;
  provinces: Province[];
  provincyRequiredInCheckout: boolean;
  zipRequiredInCheckout: boolean;
  supportedLanguages: Language[];
  shopifyKeys: ShopifyKeys;
  omsConnector: OMSConnector;
  collections: CollectionConfigs;
  koalaCollections: KoalaCollectionConfigs;
  checkoutMethod: "standard" | "in-house";
  shipping: Shipping;
  pickupShipping?: Shipping;
  productsData: ProductInternalData[];
  paymentMethods: SupportedPaymentMethod[];
  freeShipping: boolean;
  hasPricesAbTest: boolean;
  topbar?: boolean;
  socialInfo: SocialInfo;
  creditsConfig?: CreditsConfig;
  referralConfig?: Omit<ReferralConfig, "code" | "affiliate">;
  newsletterModalConfig?: {
    discount: Amount;
  };
  bundleDiscountSystems?: BundleDiscountSystem[];
  // setDiscountSystems?: SetDiscountSystem[];
  splitTestDiscount?: {
    discountAmount: number;
    discountType: "percentage";
  };
  paymentGatewaySplitTestActive?: boolean;
  storeLocations?: StoreLocation[];
  storeLocatorMapCenter?: {
    lng: number;
    lat: number;
  };
  christmasTheme: boolean;
  multiCategory: boolean;
  multiColors: boolean;
  pargoPickupPoints: boolean;
  hasGiftCards: boolean;
  hasAccessories: boolean;
  ramadanTheme: boolean;
  areDiscountsManagedFromShopify: boolean;
  isDiscountCodeAllowed: boolean;
  homeHeroImage: number;
  oms: "shopify" | "koala";
  orderScopes: string[];
  pl3Connector: string;
  fakeDiscountPerProduct?: {
    [key: string]: number;
  };
}

class Country implements CountryProps {
  name: CountryName;
  code: CountryCode;
  currency: Currency;
  numberOfCents: number;
  region: Region;
  phoneExample: string;
  provinces: Province[];
  provincyRequiredInCheckout;
  zipRequiredInCheckout: boolean;
  supportedLanguages: Language[];
  shopifyKeys: ShopifyKeys;
  omsConnector: OMSConnector;
  collections: CollectionConfigs;
  koalaCollections: KoalaCollectionConfigs;
  checkoutMethod: "standard" | "in-house";
  shipping: Shipping;
  pickupShipping?: Shipping;
  productsData: ProductInternalData[];
  freeShipping: boolean;
  hasPricesAbTest: boolean;
  topbar?: boolean;
  socialInfo: SocialInfo;
  creditsConfig?: CreditsConfig;
  referralConfig?: Omit<ReferralConfig, "code" | "affiliate">;
  newsletterModalConfig?: {
    discount: Amount;
  };
  paymentMethods: SupportedPaymentMethod[];
  bundleDiscountSystems?: BundleDiscountSystem[];
  // setDiscountSystems?: SetDiscountSystem[];
  splitTestDiscount?: {
    discountAmount: number;
    discountType: "percentage";
  };
  paymentGatewaySplitTestActive?: boolean;
  storeLocations?: StoreLocation[] | undefined;
  storeLocatorMapCenter?: {
    lng: number;
    lat: number;
  };
  christmasTheme: boolean;
  multiCategory: boolean;
  multiColors: boolean;
  pargoPickupPoints: boolean;
  hasGiftCards: boolean;
  hasAccessories: boolean;
  ramadanTheme: boolean;
  areDiscountsManagedFromShopify: boolean;
  isDiscountCodeAllowed: boolean;
  homeHeroImage: number;
  oms: "shopify" | "koala";
  orderScopes: string[];
  pl3Connector: string;
  fakeDiscountPerProduct?: {
    [key: string]: number;
  };

  constructor({
    name,
    code,
    currency,
    region,
    provinces,
    phoneExample,
    provincyRequiredInCheckout,
    zipRequiredInCheckout,
    supportedLanguages,
    shopifyKeys,
    omsConnector,
    collections,
    koalaCollections,
    checkoutMethod,
    shipping,
    pickupShipping,
    productsData,
    freeShipping,
    hasPricesAbTest,
    topbar = true,
    socialInfo,
    numberOfCents,
    creditsConfig,
    referralConfig,
    newsletterModalConfig,
    paymentMethods,
    bundleDiscountSystems,
    // setDiscountSystems,
    splitTestDiscount,
    paymentGatewaySplitTestActive,
    storeLocations,
    storeLocatorMapCenter,
    christmasTheme,
    multiCategory,
    multiColors,
    pargoPickupPoints,
    hasGiftCards,
    hasAccessories,
    ramadanTheme,
    areDiscountsManagedFromShopify,
    isDiscountCodeAllowed,
    homeHeroImage,
    oms,
    orderScopes,
    pl3Connector,
    fakeDiscountPerProduct,
  }: CountryProps) {
    this.name = name;
    this.code = code;
    this.currency = currency;
    this.numberOfCents = numberOfCents;
    this.region = region;
    this.phoneExample = phoneExample;
    this.provinces = provinces;
    this.provincyRequiredInCheckout = provincyRequiredInCheckout;
    this.zipRequiredInCheckout = zipRequiredInCheckout;
    this.supportedLanguages = supportedLanguages;
    this.shopifyKeys = shopifyKeys;
    this.omsConnector = process.env.NEXT_PUBLIC_ENV === "production" ? omsConnector : "shopify-qamri";
    this.collections = collections;
    this.koalaCollections = koalaCollections;
    this.checkoutMethod = checkoutMethod;
    this.shipping = shipping;
    this.pickupShipping = pickupShipping;
    this.productsData = productsData;
    this.freeShipping = freeShipping;
    this.hasPricesAbTest = hasPricesAbTest;
    this.topbar = topbar;
    this.socialInfo = socialInfo;
    this.paymentMethods = paymentMethods;
    this.referralConfig = referralConfig;
    this.newsletterModalConfig = newsletterModalConfig;
    this.creditsConfig = creditsConfig;
    this.bundleDiscountSystems = bundleDiscountSystems;
    this.splitTestDiscount = splitTestDiscount;
    this.paymentGatewaySplitTestActive = paymentGatewaySplitTestActive;
    this.storeLocations = storeLocations;
    this.storeLocatorMapCenter = storeLocatorMapCenter;
    this.christmasTheme = christmasTheme;
    this.multiCategory = multiCategory;
    this.multiColors = multiColors;
    this.pargoPickupPoints = pargoPickupPoints;
    this.hasGiftCards = hasGiftCards;
    this.hasAccessories = hasAccessories;
    this.ramadanTheme = ramadanTheme;
    this.areDiscountsManagedFromShopify = areDiscountsManagedFromShopify;
    this.isDiscountCodeAllowed = isDiscountCodeAllowed;
    this.homeHeroImage = homeHeroImage;
    this.oms = oms;
    this.orderScopes = orderScopes;
    this.pl3Connector = pl3Connector;
    this.fakeDiscountPerProduct = fakeDiscountPerProduct;
  }

  deploymentProductData(shopifyProductId = ""): ProductInternalData {
    const iShopifyProductId = parseInt(shopifyProductId.replace("gid://shopify/Product/", ""));
    const product =
      this.productsData.find((product) => product.shopifyIds.includes(iShopifyProductId)) || this.productsData[0];
    return product;
  }

  getTotalReviewsCount(startingAmount = 27500, divideBy = 1): string {
    const delta = parseInt(
      "" +
        ((((new Date().getTime() - new Date("2022-10-01T00:00:00").getTime()) / (1000 * 60 * 60)) * 2) / divideBy +
          startingAmount)
    ).toLocaleString();
    return delta;
  }

  productRatingsCount(shopifyProductId?: string): string | null {
    const productData = this.deploymentProductData(shopifyProductId || "");
    const productRatingsCount = productData.ratings;
    if (productRatingsCount > 0) {
      return this.getTotalReviewsCount(productRatingsCount);
    }
    return null;
  }

  getLocalizedProvinces(t: (string: string) => string): Province[] {
    const localizedProvinces = this.provinces.map((province) => ({
      label: t(province.label),
      value: province.value,
    }));
    // sort
    localizedProvinces.sort((provinceA, provinceB) => provinceA.label.localeCompare(provinceB.label));
    return localizedProvinces;
  }
}

export default Country;

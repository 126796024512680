import Link from "next/link";
import { memo, useEffect, useState } from "react";
import classNames from "src/helpers/classNames";
import Preview from "./components/Preview";
import { useTranslations } from "next-intl";
import { Product, SelectedOption, Image as StorefrontImage } from "src/types/storefront";
import useCountryData from "src/hooks/useCountryData";
import generatePriceBeforeDiscount from "src/helpers/generatePriceBeforeDiscount";
import getCheapestVariant from "src/helpers/storefront-helpers/getCheapestVariant";
import useAbVariantPrice from "src/hooks/useAbVariantPrice";
import consts from "src/config/consts";
import getVariant from "src/helpers/inventory-helpers/getVariant";
import getOptionValuesWithStock from "src/helpers/inventory-helpers/getOptionValuesWithStock";
import AbsorptionPicker from "../absorptions/AbsorptionPicker";
import Colors from "src/components/colors/ColorPicker";
import Image from "next/image";
import getImagesBySku from "src/helpers/storefront-helpers/getVariantImages";
import isProductInCollection from "src/helpers/storefront-helpers/isProductInCollection";
import GiftCardAmount from "src/sections/product-overview/components/gift-card/GiftCardAmount";

type ProductItemProps = {
  product: Product;
  onClick?: Function;
  withHoverButton?: boolean;
  isMobileMenu: boolean;
  imageClassName?: string;
};

function ProductItem({ product, withHoverButton = true, onClick = () => {}, imageClassName = "" }: ProductItemProps) {
  // Dependencies
  const t = useTranslations("ProductItem");
  const countryData = useCountryData();
  const cheapestVariant = getCheapestVariant(product);
  // const isFakeDiscountTestActive = useGrowthbookFeature("fake-discount", false);
  // States
  const [productRatingsCount, setProductRatingsCount] = useState<string | null>(null);
  /**
   * Selected variant
   */
  const initalVariantOptions = getCheapestVariant(product).selectedOptions;
  const [variantOptions, setVariantOptions] = useState(initalVariantOptions);
  const selectedVariant = getVariant(product, variantOptions);

  const absorptionValuesWithStock = getOptionValuesWithStock(
    product,
    "Absorption",
    variantOptions.filter(({ name }) => !["Absorption", "Size"].includes(name))
  );
  const colorValuesWithStock = getOptionValuesWithStock(
    product,
    "Color",
    variantOptions.filter(({ name }) => !["Color", "Size"].includes(name))
  );

  const amountValuesWithStock = getOptionValuesWithStock(
    product,
    "Amount",
    variantOptions.filter(({ name }) => !["Amount"].includes(name))
  );

  const handleOptionChange = (selectedOption: SelectedOption) => {
    setVariantOptions((prevOptions) =>
      prevOptions.filter((option) => option.name !== selectedOption.name).concat(selectedOption)
    );
  };

  // Effects
  useEffect(() => {
    if (countryData) {
      const rating = countryData.productRatingsCount(product.id);
      setProductRatingsCount(rating);
    }
  }, [countryData]);

  // Calculations
  /**
   * price
   */
  const price = useAbVariantPrice(selectedVariant);
  // const price = Number(selectedVariant.price.amount);
  let priceBeforeDiscount: number | null = null;

  if (
    price &&
    countryData?.splitTestDiscount &&
    !isProductInCollection(product.collections, countryData?.collections.COLLECTION_GIFTS.id)
  ) {
    priceBeforeDiscount = generatePriceBeforeDiscount(
      Number(price),
      countryData.fakeDiscountPerProduct?.[product.handle] || consts.FAKE_DISCOUNT_PERCENTAGE_VALUE
    );
  }

  /**
   * currency
   */
  let currency = selectedVariant?.price.currencyCode;
  if (currency === "USD") currency = "$";

  /**
   * images
   */
  let variantImages: StorefrontImage[] = [];
  if (selectedVariant) {
    variantImages = getImagesBySku(selectedVariant.sku, product.images);
  }

  return (
    <article className="flex h-full flex-col gap-y-3">
      {/* Preload all images */}
      {product.images.map((image) => (
        <Image
          className="absolute"
          key={image.url}
          src={image.url}
          alt={""}
          sizes="
        (min-width: 20em) 12rem,
        (min-width: 27em) 21rem,
        (min-width: 64em) 15rem,
        50vw"
          width={0}
          height={0}
        />
      ))}

      {/* Image */}
      <Link
        href={{
          pathname: `/products/${product.handle}`,
          query: variantOptions.reduce((acc, { name, value }) => Object.assign(acc, { [name]: value }), {}),
        }}
        className={classNames("product-item group relative block", `products-list__${product.handle}`)}
        aria-label="product_item"
        onClick={() => onClick()}
      >
        {/* .product-item class is added fpr e2e testing */}
        <Preview images={variantImages.slice(0, 2)} imageClassName={imageClassName} />
        {withHoverButton && (
          <div className="absolute bottom-12 left-1/2 z-10 hidden min-w-[70%] -translate-x-1/2 px-2 opacity-0 transition-opacity group-hover:opacity-100 sm:block">
            <div className="shemsiBtn shemsiBtn-primary w-full ">{t("shop_now")}</div>
          </div>
        )}
      </Link>

      {/* Product details */}
      <div className="flex flex-1 flex-col gap-y-2">
        {/* handle and price */}
        <div className="flex items-start justify-between sm:items-center">
          {/* handle */}
          <h3 className="font-semibold">
            <Link
              href={{
                pathname: `/products/${product.handle}`,
                query: variantOptions.reduce((acc, { name, value }) => Object.assign(acc, { [name]: value }), {}),
              }}
              className="block"
              onClick={() => onClick()}
            >
              {product.title}
            </Link>
          </h3>

          {/* Price */}
          <div className="flex flex-col gap-x-2 sm:flex-row sm:items-center">
            {/* compare at price */}
            {priceBeforeDiscount && (
              <p className="shrink-0 text-xs font-thin text-red-500 line-through">
                {t("price", {
                  amount: priceBeforeDiscount,
                  currency,
                })}
              </p>
            )}
            {/* actual price */}
            {price && (
              <p className={classNames("shrink-0 font-semibold")}>
                {t("price", {
                  amount: price,
                  currency,
                })}
              </p>
            )}
          </div>
        </div>

        <div className="">
          {/* Absorptions */}
          {absorptionValuesWithStock && (
            <AbsorptionPicker
              valuesWithStock={absorptionValuesWithStock}
              selectedAbsorption={variantOptions.find((option) => option.name === "Absorption")?.value || null}
              onChange={(value) => handleOptionChange({ name: "Absorption", value })}
            />
          )}

          {/* Colors */}
          {colorValuesWithStock && (
            <Colors
              selectedColor={variantOptions.find((option) => option.name === "Color")?.value || ""}
              setSelectedColor={(value) => handleOptionChange({ name: "Color", value })}
              valuesWithStock={colorValuesWithStock}
            />
          )}
          {amountValuesWithStock && (
            <GiftCardAmount
              selectedAmount={variantOptions.find((option) => option.name === "Amount")?.value || ""}
              setSelectedAmount={(value) => handleOptionChange({ name: "Amount", value })}
              valuesWithStock={amountValuesWithStock}
            />
          )}
        </div>

        {/* <hr className="mt-auto h-0.5 bg-gray-600" /> */}

        {/* Reviews */}
        {/* <div className="flex items-center gap-2">
          <Stars
            rating={5}
            starClassName={classNames(
              "text-gray-900 w-3.5",
              !isMobileMenu ? "sm:w-4" : ""
            )}
          />
          <p
            className={classNames("text-xs", !isMobileMenu ? "sm:text-sm" : "")}
            suppressHydrationWarning={true}
          >
            {spaceToNbsp(t("n_reviews", { count: productRatingsCount }))}
          </p>
        </div> */}
      </div>
    </article>
  );
}

export default memo(ProductItem);

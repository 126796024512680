import Country, { KoalaCollectionConfigs } from "src/helpers/localization-helpers/countryClass";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { creditsConfig } from "../credits.config";
import { CountryName } from "src/types/localization/CountryName";
import { CountryCode } from "src/types/localization/CountryCode";
import { Currency } from "src/types/localization/Currency";
import { LanguageName } from "src/types/localization/LanguageName";
import { LanguageCode } from "src/types/localization/LanguageCode";
import { StoreType } from "src/types/enums/stores.enum";
import { StoreLocation } from "src/types/store-locations";
import { bundleDiscountSystems } from "../bundle/defaultBundleDiscountSystems";
import consts from "../consts";

const env = process.env.NEXT_PUBLIC_ENV;

const shopifyKeys = {
  STORE_NAME: process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME_EG ?? "",
  STOREFRONT_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN_EG ?? "",
  ADMIN_TOKEN: process.env.SHOPIFY_ADMIN_TOKEN_EG ?? "",
};

const koalaCollections: KoalaCollectionConfigs = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: ["production", "preproduction"].includes(env) ? "666148b6d9470fe2c0ea8845" : "664f4f8a0a3fd8204e8baffc",
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: ["production", "preproduction"].includes(env) ? "666148a7d9470fe2c0ea8843" : "66545cefe4ba7f199fda9f9d",
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: false,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: ["production", "preproduction"].includes(env) ? "666148c0d9470fe2c0ea8847" : "66545cf5e4ba7f199fda9f9f",
    name: "ladies",
    isActive: true,
    hasSeparateList: false,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_TEENS: {
    id: ["production", "preproduction"].includes(env) ? "666148c5d9470fe2c0ea8849" : "66545cfae4ba7f199fda9fa1",
    name: "teens",
    isActive: true,
    hasSeparateList: false,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_ACCESSORIES: {
    id: ["production", "preproduction"].includes(env) ? "666148d0d9470fe2c0ea884e" : "66545cffe4ba7f199fda9fa7",
    name: "accessories",
    isActive: true,
    hasSeparateList: true,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
};

const collections = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 412573860067,
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_SINGLE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431158940 : 412573958371,
    name: "all_single",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_PACKS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 412573860067,
    name: "packs",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298026959004 : 432005677283,
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: false,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751806108 : 431180742883,
    name: "ladies",
    isActive: true,
    hasSeparateList: false,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_TEENS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297688531100 : 431180775651,
    name: "teens",
    isActive: true,
    hasSeparateList: false,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_GIFTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298701947036 : 434506465507,
    name: "gifts",
    isActive: true,
    hasSeparateList: true,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751740572 : 431180808419,
    name: "accessories",
    isActive: true,
    hasSeparateList: true,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
};

const shipping = {
  line: {
    title: "Standard",
    price: "46.00",
  },
  fees: 46,
  freeLimit: 750,
};

const pickupShipping = {
  line: {
    title: "Standard",
    price: "30.00",
  },
  fees: 30,
  freeLimit: 750,
};

const socialInfo = {
  WHATSAPP_SUPPORT_NUMBER: "+971585096963",
  FACEBOOK_PAGE_HANDLE: "myshemsi",
  INSTAGRAM_HANDLE: "myshemsi",
  YOUTUBE_CHANNEL: "UCS0B5xRuLR8KZTsQTmDmrHg",
  TIKTOK_CHANNEL: "myshemsi",
  SUPPORT_START_HOUR: 8,
  SUPPORT_END_HOUR: 1,
};

const productsData = [
  {
    internalName: "ipanema",
    shopifyIds: [
      // Dev
      7060457160840, 7075133456520,

      // Prod
      7933561700579,

      // Prod packs
      7937883013347,

      // staging single
      7481930645660,

      // staging packs
      7481929564316,
    ],
    ratings: 12500,
  },
  {
    internalName: "bahia",
    shopifyIds: [
      // Dev
      7060425605256, 7075128737928,

      // Prod
      7937881014499,

      // Prod packs
      7937882947811,

      // staging single
      7481930383516,

      // staging packs
      7481929891996,
    ],
    ratings: 5600,
  },
  {
    internalName: "alexandria",
    shopifyIds: [
      // Dev
      7060417052808, 7075132670088,

      // Prod
      7937880948963,

      // Prod pack alexandria
      7937882915043,

      // staging single
      7481930481820,

      // staging packs
      7481930121372,
    ],
    ratings: 7520,
  },
  {
    internalName: "havana",
    shopifyIds: [
      // Dev
      7075118612616, 7060449493128,

      // Prod
      7937881112803,

      // Prod pack
      7937882980579,

      // staging single
      7481930285212,

      // staging packs
      7481929760924,
    ],
    ratings: 9500,
  },
  {
    internalName: "copacabana",
    shopifyIds: [
      // Dev
      7060449493128,

      // Prod
      8273609588963,

      // Prod pack
      7937882980579,

      // staging single
      7846874382492,

      // staging packs
      7481929760924,
    ],
    ratings: 420,
  },
  {
    internalName: "waikiki",
    shopifyIds: [
      // Dev
      7060449493128,

      // Prod
      8281272582371,

      // Prod pack
      7937882980579,

      // staging single
      7846874284188,

      // staging packs
      7481929760924,
    ],
    ratings: 342,
  },
];

// Label field should be localized
const provinces = [
  { label: "6th_of_october", value: "6th of October" },
  { label: "al_sharqia", value: "Al Sharqia" },
  { label: "alexandria", value: "Alexandria" },
  { label: "aswan", value: "Aswan" },
  { label: "asyut", value: "Asyut" },
  { label: "beheira", value: "Beheira" },
  { label: "beni_suef", value: "Beni Suef" },
  { label: "cairo", value: "Cairo" },
  { label: "dakahlia", value: "Dakahlia" },
  { label: "damietta", value: "Damietta" },
  { label: "faiyum", value: "Faiyum" },
  { label: "gharbia", value: "Gharbia" },
  { label: "giza", value: "Giza" },
  { label: "helwan", value: "Helwan" },
  { label: "ismailia", value: "Ismailia" },
  { label: "kafr_el_sheikh", value: "Kafr el-Sheikh" },
  { label: "luxor", value: "Luxor" },
  { label: "matrouh", value: "Matrouh" },
  { label: "minya", value: "Minya" },
  { label: "monufia", value: "Monufia" },
  { label: "new_valley", value: "New Valley" },
  // { label: "north_sinai", value: "North Sinai" },
  { label: "port_said", value: "Port Said" },
  { label: "qalyubia", value: "Qalyubia" },
  { label: "qena", value: "Qena" },
  { label: "red_sea", value: "Red Sea" },
  { label: "sohag", value: "Sohag" },
  // { label: "south_sinai", value: "South Sinai" },
  { label: "suez", value: "Suez" },
];

export const storeLocations: StoreLocation[] = [
  {
    storeName: "al_nadi_pharmacy_dandy",
    address: "al_nadi_pharmacy_dandy_address",
    open: false,
    storeTiming: "9 AM - 12 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 24, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.027492610448345, lat: 30.063430139140223 },
    placeId: "ChIJ-7IwKl3tWBQR0TJaqQ-UzRw",
    googleMapsLink: "https://maps.google.com/?cid=2075477798274347729",
  },
  {
    storeName: "al_nadi_pharmacy_moa",
    address: "al_nadi_pharmacy_moa_address",
    open: false,
    storeTiming: "9 AM - 12 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 24, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 30.9742127067462, lat: 30.0057146909743 },
    placeId: "ChIJE8uaQURXWBQRdNImfmqa0oE",
    googleMapsLink: "https://maps.google.com/?cid=9354709158175625844",
  },
  {
    storeName: "al_nadi_pharmacy_hyper_one",
    address: "al_nadi_pharmacy_hyper_one_address",
    open: false,
    storeTiming: "9 AM - 12 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 24, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.021797727642877, lat: 30.030454837839326 },
    placeId: "ChIJE1OZMkNaWBQRtuzlPH5EGLg",
    googleMapsLink: "https://maps.google.com/?cid=13265428011398065334",
  },
  {
    storeName: "al_nadi_pharmacy_palm_hills",
    address: "al_nadi_pharmacy_palm_hills_address",
    open: false,
    storeTiming: "8:30 AM - 1 AM",
    openingHours: { hours: 8, minutes: 30 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.025497606746203, lat: 29.99898162252649 },
    placeId: "ChIJIycfZnRRWBQRM7A0RZ05YZU",
    googleMapsLink: "https://maps.google.com/?cid=10763947932025925683",
  },
  {
    storeName: "al_nadi_pharmacy_americana_plaza",
    address: "al_nadi_pharmacy_americana_plaza_address",
    open: false,
    storeTiming: "24 Hours",
    openingHours: { hours: 0, minutes: 0 },
    closingHours: { hours: 24, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.01297314458695, lat: 30.028236310184674 },
    placeId: "ChIJd0uF635bWBQRkHCSq6EaK3w",
    googleMapsLink: "https://maps.google.com/?cid=8947274366372180112",
  },
  {
    storeName: "dr_germin_pharmacy",
    address: "dr_germin_pharmacy_address",
    open: false,
    storeTiming: "9 AM - 11 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.01621415397233, lat: 30.016017934004225 },
    placeId: "ChIJz1ZzPlVbWBQRrRygAWkQcNs",
    googleMapsLink: "https://maps.google.com/?cid=15812156334882692269",
  },
  {
    storeName: "dr_youssry_abdelwoudoud_pharmacy",
    address: "dr_youssry_abdelwoudoud_pharmacy_address",
    open: false,
    storeTiming: "9 AM - 11 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.326412847816304, lat: 30.091174811644553 },
    placeId: "ChIJsVcNcMU_WBQRHXNe-KmvB6E",
    googleMapsLink: "https://maps.app.goo.gl/VqkCFvakZZgCru7r6",
  },
  {
    storeName: "el_hayah_pharmacy_mokattam_branch",
    address: "el_hayah_pharmacy_mokattam_branch_address",
    open: false,
    storeTiming: "9 AM - 11 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.33166208650809, lat: 30.011775377587323 },
    placeId: "ChIJORbyPk4_WBQRLVqibIvir5o",
    googleMapsLink: "https://maps.app.goo.gl/MEMY2kqJk7V51Rzi7",
  },
  {
    storeName: "roshdy_pharmacy",
    address: "roshdy_pharmacy_address",
    open: false,
    storeTiming: "24 Hours",
    openingHours: { hours: 0, minutes: 0 },
    closingHours: { hours: 24, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.202931906745953, lat: 30.055261039180568 },
    placeId: "ChIJleVduTVBWBQR2dgA4lJVy8M",
    googleMapsLink: "https://maps.app.goo.gl/2cu6EAEDvRcFWh1g7",
  },
  {
    storeName: "el_hayah_mohandeseen_branch",
    address: "el_hayah_mohandeseen_branch_address",
    open: false,
    storeTiming: "9 AM - 11 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.20702815651739, lat: 30.062815827797237 },
    placeId: "ChIJg7Truqg5WBQRcC7Jd2ai6j1",
    googleMapsLink: "https://maps.app.goo.gl/mZNoP9a2jRfmNpHv8",
  },
  {
    storeName: "el_hayah_north_coast_branch",
    address: "el_hayah_north_coast_branch_address",
    open: false,
    storeTiming: "9 AM - 11 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 29.13395378396289, lat: 30.81495855699668 },
    placeId: "ChIJg7Truqg5SBQRcH8Jd2ai6j1",
    googleMapsLink: "https://maps.app.goo.gl/nEXPND8ER2E7WSxM9",
  },
  {
    storeName: "el_hayah_maadi_branch",
    address: "el_hayah_maadi_branch_address",
    open: false,
    storeTiming: "9 AM - 11 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.257949357654983, lat: 29.961056932243782 },
    placeId: "ChIOg0Trsqg5SBQRcH8Jd2ai6j1",
    googleMapsLink: "https://maps.app.goo.gl/n2FbcjoGfqwYQ6nv5",
  },
  {
    storeName: "el_hayah_pharmacy_leven_square",
    address: "el_hayah_pharmacy_leven_square_address",
    open: false,
    storeTiming: "8 AM - 12 AM",
    openingHours: { hours: 8, minutes: 0 },
    closingHours: { hours: 24, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.48569971991546, lat: 30.0464477072495 },
    placeId: "ChIJv5qr-7ojWBQRMQKaei1fFt8",
    googleMapsLink: "https://maps.app.goo.gl/71Pb4kpsgGAKLgqJA",
  },
  {
    storeName: "el_hayah_pharmacy_midtown",
    address: "el_hayah_pharmacy_midtown_address",
    open: false,
    storeTiming: "7 AM - 10 PM",
    openingHours: { hours: 7, minutes: 0 },
    closingHours: { hours: 22, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.501385744010094, lat: 30.01336086602033 },
    placeId: "ChIJQ8XsOFAiWBQRSvy5MBdmgo0",
    googleMapsLink: "https://maps.app.goo.gl/VU6osokMFS5yzXMHA",
  },
  {
    storeName: "el_hayah_pharmacy_rehab_branch",
    address: "el_hayah_pharmacy_rehab_branch_address",
    open: false,
    storeTiming: "8 AM - 11 PM",
    openingHours: { hours: 8, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.51592060626155, lat: 30.070827003478026 },
    placeId: "ChIJsbLw894_WBQR8Uml5pNzSK8",
    googleMapsLink: "https://maps.app.goo.gl/iTmy1si6jNNq94Wb7",
  },
  {
    storeName: "zikry_pharmacies_safeer_branch",
    address: "zikry_pharmacies_safeer_branch_address",
    open: false,
    storeTiming: "8 AM - 2 AM",
    openingHours: { hours: 8, minutes: 0 },
    closingHours: { hours: 2, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.346804414136727, lat: 30.086866708475995 },
    placeId: "ChIJ9eiKxZoVWBQRSJMmTQebl0U",
    googleMapsLink: "https://maps.app.goo.gl/2PMDMNndbmURhhhf8",
  },
  {
    storeName: "zikry_pharmacies_merghany_branch",
    address: "zikry_pharmacies_merghany_branch_address",
    open: false,
    storeTiming: "8 AM - 2 AM",
    openingHours: { hours: 8, minutes: 0 },
    closingHours: { hours: 2, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.326046294155855, lat: 30.08567843885056 },
    placeId: "ChIJnYqaeSM-WBQRfLVVCwwi0tw",
    googleMapsLink: "https://maps.app.goo.gl/2Rz96VkYkpwLzxDz5",
  },
  {
    storeName: "zikry_pharmacies_makram_branch",
    address: "zikry_pharmacies_makram_branch_address",
    open: false,
    storeTiming: "9 AM - 1 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.34351622325441, lat: 30.06239197073254 },
    placeId: "ChIJFWJ8zyc-WBQRWe_IAoOY8Sc",
    googleMapsLink: "https://maps.app.goo.gl/Rr8hgmrSsejFc9iE9",
  },
  {
    storeName: "zikry_pharmacies_new_cairo_1_branch",
    address: "zikry_pharmacies_new_cairo_1_branch_address",
    open: false,
    storeTiming: "9 AM - 3 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 3, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.461194089579557, lat: 30.058684146410243 },
    placeId: "ChIJaWAFX3MZWBQRgiDrL9pfFz8",
    googleMapsLink: "https://maps.app.goo.gl/Su8kZuQjVRQw1HXk7",
  },
  {
    storeName: "zikry_pharmacies_el_nozha_branch",
    address: "zikry_pharmacies_el_nozha_branch_address",
    open: false,
    storeTiming: "9 AM - 1 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.33832938714852, lat: 30.07458194077811 },
    placeId: "ChIJnYqaeSM-WBQRfLVVCwwi0tw",
    googleMapsLink: "https://maps.app.goo.gl/xrXdgoM5heNNxyu76",
  },
  {
    storeName: "zikry_pharmacies_el_thawra_branch",
    address: "zikry_pharmacies_el_thawra_branch_address",
    open: false,
    storeTiming: "9 AM - 1 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.346009646140686, lat: 30.081960500904515 },
    placeId: "ChIJed4Yj_w_WBQR8Bsf9GymUdw",
    googleMapsLink: "https://maps.app.goo.gl/4rGYrrUKpMACm7cw8",
  },
  {
    storeName: "zikry_pharmacies_el_nasr_branch",
    address: "zikry_pharmacies_el_nasr_branch_address",
    open: false,
    storeTiming: "9 AM - 1 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.344592554534675, lat: 30.07014256996549 },
    placeId: "ChIJg9LKOM4_WBQReKO53q43Scw",
    googleMapsLink: "https://maps.app.goo.gl/NzQw7pzZAAWX4E1U9",
  },
  {
    storeName: "zikry_pharmacies_golf_branch",
    address: "zikry_pharmacies_golf_branch_address",
    open: false,
    storeTiming: "9 AM - 1 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.335661164411935, lat: 30.083917117898636 },
    placeId: "ChIJM6ojGhk-WBQR_SVCS9qU7OQ",
    googleMapsLink: "https://maps.app.goo.gl/Vu5QDrDb1kTYtChD8",
  },
  {
    storeName: "zikry_pharmacies_abo_bakr_el_sedeek_branch",
    address: "zikry_pharmacies_abo_bakr_el_sedeek_branch_address",
    open: false,
    storeTiming: "9 AM - 1 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.325777184467782, lat: 30.105696356884074 },
    placeId: "ChIJIy-fmzEVWBQR6T7VR-wjhsE",
    googleMapsLink: "https://maps.app.goo.gl/BkJgizKTFeUJK4eH7",
  },
  {
    storeName: "zikry_pharmacies_sheraton_branch",
    address: "zikry_pharmacies_sheraton_branch_address",
    open: false,
    storeTiming: "9 AM - 2 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 2, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.373449512975434, lat: 30.10641793774295 },
    placeId: "ChIJkQlvYmkWWBQRICFsFDAgqfg",
    googleMapsLink: "https://maps.app.goo.gl/euvBPFScprrpaSrV9",
  },
  {
    storeName: "zikry_pharmacies_roxy_branch",
    address: "zikry_pharmacies_roxy_branch_address",
    open: false,
    storeTiming: "9 AM - 1 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 1, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.319909208066584, lat: 30.091833309115927 },
    placeId: "ChIJ_____yY-WBQRUsjeqy1GOIc",
    googleMapsLink: "https://maps.app.goo.gl/XDQvYF7iBph8Jyr3A",
  },
  {
    storeName: "zikry_pharmacies_syria_branch",
    address: "zikry_pharmacies_syria_branch_address",
    open: false,
    storeTiming: "9 AM - 2 AM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 2, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.192554283500865, lat: 30.052110243280637 },
    placeId: "ChIJ42B6Q6ZBWBQR9fUGhL-5F5E",
    googleMapsLink: "https://maps.app.goo.gl/xPoFq3eKLMqDLPX87",
  },
  {
    storeName: "zikry_pharmacies_military_academy_branch",
    address: "zikry_pharmacies_military_academy_branch_address",
    open: false,
    storeTiming: "24 Hours",
    openingHours: { hours: 0, minutes: 0 },
    closingHours: { hours: 24, minutes: 0 },
    storeType: StoreType.Pharmacy,
    coordinates: { lng: 31.356047586283974, lat: 30.114648706618354 },
    placeId: "ChIJG6f3vHcWWBQR_8CVElIFAUM",
    googleMapsLink: "https://maps.app.goo.gl/hD8NV6xyjEGTTWLX8",
  },
];
// In case user doesn't have their location services on
export const storeLocatorMapCenter = {
  lng: 31.22976475982613,
  lat: 30.05254418445372,
};

const EG = new Country({
  name: CountryName.Egypt,
  code: CountryCode.EG,
  currency: Currency.EGP,
  numberOfCents: 100,
  region: "MENA",
  phoneExample: "01223806047",
  provinces,
  provincyRequiredInCheckout: true,
  zipRequiredInCheckout: false,
  supportedLanguages: [
    {
      label: LanguageName.العربية,
      value: LanguageCode.ar,
    },
    {
      label: LanguageName.English,
      value: LanguageCode.en,
      default: true,
    },
  ],
  shopifyKeys,
  omsConnector: "shopify-egypt",
  collections,
  koalaCollections,
  checkoutMethod: "in-house",
  shipping,
  pickupShipping,
  productsData,
  freeShipping: true,
  socialInfo,
  creditsConfig,
  referralConfig: {
    newRefereeOnly: true,
    referralCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
    refereeCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
  },
  newsletterModalConfig: env !== "ci" ? { discount: { amount: 10, amountType: "percentage" } } : undefined,
  paymentMethods: [
    SupportedPaymentMethod.cashOnDelivery,
    // SupportedPaymentMethod.creditCard,
    SupportedPaymentMethod.fawryCreditCard,
    SupportedPaymentMethod.referenceNumber,
    // SupportedPaymentMethod.wallet,
    SupportedPaymentMethod.fawryBankInstallments,
    // SupportedPaymentMethod.valU,
    ...(env != "production" ? [SupportedPaymentMethod.creditCard] : []),
  ],
  bundleDiscountSystems,
  splitTestDiscount: {
    discountAmount: consts.FAKE_DISCOUNT_PERCENTAGE_VALUE,
    discountType: "percentage",
  },
  // env === "production":
  // ?
  // : undefined,
  paymentGatewaySplitTestActive: true,
  storeLocations: storeLocations,
  storeLocatorMapCenter: storeLocatorMapCenter,
  hasPricesAbTest: true,
  christmasTheme: false,
  multiCategory: true,
  multiColors: true,
  pargoPickupPoints: true,
  hasGiftCards: true,
  hasAccessories: true,
  ramadanTheme: false,
  areDiscountsManagedFromShopify: false,
  isDiscountCodeAllowed: true,
  homeHeroImage: ["production", "preproduction"].includes(env)
    ? 7937881014499
    : ["staging", "ci"].includes(env)
    ? 7481930481820
    : 7060417052808,
  // oms: ["production", "preproduction", "ci"].includes(env) ? "shopify" : "koala",
  oms: "koala",
  orderScopes: ["fulfil", "nps_free", "referral"],
  pl3Connector: "khazenly-egypt",
  fakeDiscountPerProduct: {
    ipanema: 45,
    bahia: 45,
    alexandria: 30,
    havana: 30,
    copacabana: 30,
    Waikiki: 30,
    "wash-bag": 30,
  },
});

export default EG;

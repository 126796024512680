export default {
  WHATSAPP_SUPPORT_NUMBER: "+971585096963",
  FACEBOOK_PAGE_HANDLE: "myshemsi",
  INSTAGRAM_HANDLE: "myshemsi",
  YOUTUBE_CHANNEL: "UCS0B5xRuLR8KZTsQTmDmrHg",
  TIKTOK_CHANNEL: "myshemsi",
  SUPPORT_START_HOUR: 8,
  SUPPORT_END_HOUR: 1,

  STRIPE_CHECK_ORDER_STATUS_INTERVAL_IN_MS: 5000,
  STRIPE_CC_MAX_ATTEMPTS: 5,

  DEFAULT_LOCALE: "ar-EG",

  VARIANT_OPTIONS: ["Size", "Absorption", "Color", "Amount", "Title", "Age"],
  TRANSLATED_VARIANT_OPTIONS: ["Size", "Absorption", "Color", "Amount"],

  CHRISTMAS_TOPBAR_HEIGHT_IN_PX: 64,

  FAKE_DISCOUNT_PERCENTAGE_VALUE: 45,

  GIFT_CARD_SKU_PREFIX: "G01",

  NEWSLETTER_DISCOUNT_VALUE: "10%",
};
